import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/recordbean",
    redirect: "/recordbean/index",
    component: MidRouterView,
    children: [
        {
            path: "index",
            name: "recordbeanIndex",
            meta: {
                title: "录音豆",
                hideHeaderNav: true
            },
            component: () => import("@/views/recordbean/index.vue"),
        },
    ],
};

export default routes;
