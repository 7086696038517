import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/studentCard",
    redirect: "/studentCard/index",
    component: MidRouterView,
    children: [
        {
            path: "index",
            name: "studentCardIndex",
            meta: {
                title: "学生证",
                hideHeaderNav: true
            },
            component: () => import("@/views/studentCard/index.vue"),
        },
        // {
        //     path: "bind",
        //     name: "studentCardBind",
        //     meta: {
        //         title: "绑定设备",
        //         hideHeaderNav: true,
        //         background: '#fff'
        //     },
        //     component: () => import("@/views/studentCard/bind.vue"),
        // },
        {
            path: "application",
            name: "studentCardApplication",
            meta: {
                title: "应用管理",
                hideHeaderNav: true
            },
            component: () => import("@/views/studentCard/application.vue"),
        },
        {
            path: "aiStudy",
            name: "studentCardAiStudy",
            meta: {
                title: "AI学习",
                hideHeaderNav: true
            },
            component: () => import("@/views/studentCard/aiStudy.vue"),
        },
        {
            path: "trafficcard",
            name: "studentCardTrafficcard",
            meta: {
                title: "交通卡",
                hideHeaderNav: true
            },
            component: () => import("@/views/studentCard/trafficcard.vue"),
        },
    ],
};

export default routes;
